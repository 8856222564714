<template>
  <v-main class="menu">
    <Header2 />

    <div class="menu1 d-flex flex-column align-center">
      <!-- QR reader -->
      <div class="qrReaderContainer">
        <qrcode-stream
          @decode="onDecode"
          @loaded="onLoaded"
        />
      </div>

      <v-card elevation="0" class="px-5 mx-16 fiscalContainer login-cart justify-center">
        <v-card-text>
          <v-form @submit.prevent="submitFiscalID" id="sign" ref="form">
            <!-- Fiscal ID -->
            <v-text-field
              class="mb-3 form_field"
              :label="'Fiscal ID'"
              v-model="fiscalID"
              :rules='rules.fiscalID'
              type="text"
              outlined
              color="#11B800"
            ></v-text-field>
          </v-form>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Submit button -->
          <v-btn
          class="px-10 buttonh"
          form="sign"
          :loading="loading"
          dark
          rounded
          type="submit"
          >{{ $t('fiscalEnter_button') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import axios from "axios"
import store from '../../store/'
import Header2 from '../parts/Header2'
import i18n from "@/plugins/i18n"
import userr from "../../store/user"
export default {
  name: 'FiscalEnter',
  components: {
    Header2,
  },
  data() {
    return {
      config: {
            headers: {
                Authorization: ""
            }
        },
      rules: {},
      fiscalID: "",
      loading: false
    }
  },
  methods: {
    onDecode(result) {
      this.fiscalID = this.extractFiscalID(result)
    },
    onLoaded(){
      console.log('ok')
    },
    extractFiscalID(website) {
      // Fiscal ID is the next 12 characters after doc=
      const startIndex = website.indexOf("doc=") + 4
      const fiscalResult = website.substring(startIndex, startIndex + 12)
      return fiscalResult
    },
    submitFiscalID() {
      if (this.$refs.form.validate()) {
        this.loading = true
        axios.post(store.getters.getUrl + "Receipt/Add", { FiscalId: this.fiscalID }, this.config)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(i18n.t("fiscal_success"));
            this.loading = false

            // Reloading after 2 seconds
            setTimeout(() => {
              location.reload()
            }, 2000)
          }
          else {
            if(response.data.errors[0].errorCode == '409'){
              this.$toast.error(i18n.t("fiscal_error2"));
              this.loading = false;
            }
            else {
              this.$toast.error(i18n.t("fiscal_error"));
              this.loading = false;
            }

          }
        }).catch((e)=>{
          console.log(e)
          this.$toast.error(i18n.t("fiscal_error2"));
          this.loading = false
        })
      }
      else {
        this.$toast.error(i18n.t('register_wrong_filling'));
        this.loading = false;
      }
    }
  },
  created() {
    this.config.headers.Authorization = "Bearer " + userr.getters.getToken;
    this.rules = {
      fiscalID: [
        val => val !== "" || i18n.t('register_error_please_fill'),
        val => val.length === 12 || i18n.t('fiscalEnter_error_length_not_enough')
      ]
    }
  },
}
</script>

<style>
.button-container {
  display: none !important;
}
.qrReaderContainer{
  max-width: 400px;
  width: 80%
}
</style>